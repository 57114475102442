<template>
    <div>

        <!-- 1L -->
        <van-sticky>
            <!-- 1.1L -->
            <van-nav-bar
                    title=""
                    :left-arrow="false"
                    @click-right="historyOrder"
            >
                <template #title>
                    {{ deviceSnTitle }}
                </template>
                <template #left>
                    <van-image width="40" height="40" radius="4" v-bind:src="user.HeadImgUrl" @click="getInfo"/>
                </template>
                <template #right>
                    <van-icon>
                        <svg class="icon" aria-hidden="true" font-size="30px">
                            <use xlink:href="#imfyc-icon-lishidingdan"></use>
                        </svg>
                    </van-icon>
                </template>
            </van-nav-bar>
        </van-sticky>
        <!-- 1.2L -->
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
            <van-sticky>
                <van-row>
                    <van-cell-group>
                        <van-cell title="选择空闲端口" :border="false">
                            <template #right-icon>
                                <van-icon name="cluster-o" color="#1989fa"/>
                            </template>
                        </van-cell>
                        <van-cell v-if="deviceIsNull === false" style="padding-top: 1px">
                            <van-grid column-num="4" :gutter="2" clickable
                                      style="padding-top: 1px;">
                                <van-grid-item v-for="(item,index) in devicePorts" :key="index" :text="item.PortSn+'号'"
                                               @click="port(item)" :ref="'ports'+item.PortSn"
                                               style="text-align: center;height: 50px;"
                                               :class="[{'port-active': (item.Status !== 1 )}]">

                                    <template #text>
                                    <span v-if="item.Status == 1">
                                        {{ item.PortSn + '号空闲'}}
                                    </span>
                                        <span v-else-if="item.Status == 0">
                                        {{ item.PortSn + '号使用'}}
                                    </span>
                                        <span v-else-if="item.Status == 2">
                                        {{ item.PortSn + '号故障'}}
                                    </span>
                                    </template>
                                </van-grid-item>
                            </van-grid>
                        </van-cell>
                    </van-cell-group>
                </van-row>
                <!-- 1.3L -->
                <van-cell title="选择套餐">
                    <template #right-icon>
                        <van-icon name="fire-o" color="#1989fa"/>
                    </template>
                </van-cell>
            </van-sticky>
            <!-- 2L -->
            <van-empty image="network" v-if="deviceIsNull === true">
                <template #description>
                    {{ description }}
                </template>
            </van-empty>
            <!-- 2.1L -->
            <van-list
                    :class="'class_list'"
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    v-else
            >
                <van-cell :class="[item.ID==discountIndex?'discount-active':'discount-normal']" center  v-for="(item,i) in discounts" :key="i" :value="'￥'+item.Price"
                          clickable @click="chooseDiscount(item)">
                    <template #icon>
                        <van-icon>
                            <svg class="icon" aria-hidden="true" font-size="24px">
                                <use xlink:href="#imfyc-icon-dianchidianya"></use>
                            </svg>
                        </van-icon>
                    </template>
                    <template #title>
                        {{ item.UnitTime +'分钟'}}
                    </template>
                    <template #label>
                        {{ item.Name }}
                    </template>
                </van-cell>
            </van-list>
        </van-pull-refresh>
        <div style="color: lightskyblue;font-size: small;padding-bottom: 60px;padding-left: 10px;padding-right: 10px">
            充电时长说明：<br/>
            充电功率为0-200瓦时，充电时长为套餐时长乘100%<br/>
            充电功率在200-300瓦时，充电时长为套餐时长乘75%<br/>
            充电功率在300-400瓦时，充电时长为套餐时长乘60%<br/>
            充电功率在400-600瓦时，充电时长为套餐时长乘50%<br/>
        </div>
        <!-- 3L -->
        <van-goods-action>
            <van-goods-action-icon color="#07c160" @click="customerService">
                <template>
                    <van-icon class-prefix="imfyc-icon" name="kefu" size="0.9rem"/>
                    客服
                </template>
            </van-goods-action-icon>
            <van-goods-action-button icon="balance-o" type="warning" text="优惠充值" @click="discount" disabled/>
            <van-goods-action-button icon="gold-coin" type="danger" text="立即支付" @click="pay"/>
        </van-goods-action>
    </div>
</template>

<script>

    import axios from "axios";
    import {getWxTokenUrl, getWxUserInfoUrl} from "../../api/base";
    import {getCallbackReq, getCallBackReq1, handleZero, isEmpty, isNotEmpty} from "../../utils/common";

    import {
        Cell,
        CellGroup,
        Col,
        Dialog,
        Empty,
        GoodsAction,
        GoodsActionButton,
        GoodsActionIcon,
        Grid,
        GridItem,
        Icon,
        Image as VanImage,
        List,
        NavBar,
        PullRefresh,
        Row,
        Sticky,
        Toast
    } from 'vant';

    export default {
        name: "pay",
        components: {
            [NavBar.name]: NavBar,
            [Col.name]: Col,
            [Row.name]: Row,
            [Dialog.Component.name]: Dialog.Component,
            [Grid.name]: Grid,
            [GridItem.name]: GridItem,
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Icon.name]: Icon,
            [List.name]: List,
            [Sticky.name]: Sticky,
            [VanImage.name]: VanImage,
            [Empty.name]: Empty,
            [GoodsAction.name]: GoodsAction,
            [GoodsActionIcon.name]: GoodsActionIcon,
            [GoodsActionButton.name]: GoodsActionButton,
            [Dialog.name]: Dialog,
            [PullRefresh.name]: PullRefresh
        },
        data() {
            return {
                active: 0,
                portId: null,
                portIndex: null,
                discountIndex: null,
                list: [],
                loading: false,
                finished: false,
                curOrderPage: 0,
                curOrderSize: 0,
                order: {},
                deviceIsNull: false,
                devicePorts: [],
                deviceSn: '',
                deviceSnTitle: '运创设备',
                discounts: [],
                codeSource: null,
                callbackReq: null,
                reqObj: {},
                user: {},
                device: null,
                curPort: null,
                curDiscount: null,
                description: '设备维护中',
                toastObj: null,
                isLoading: false
            };
        },
        watch: {},
        mounted() {
            //
            this.initData();
        }

        , methods: {
            //
            onRefresh() {
                this.isLoading = true;
                this.deviceSnTitle = this.deviceSnTitle.split('-')[0];
                this.deviceIsNull = false;
                this.initData();
            }
            // 初始化大本营
            , initData() {
                //
                this.checkDeviceSn();
                if (this.deviceIsNull) {
                    return;
                }
                //
                this.getUserBySession();
                if (Object.keys(this.user).length > 0) {
                    return;
                }
                //
                this.callbackReq = getCallbackReq();
                //
                this.getCodeSource();
                //
                this.checkCallbackAndSource();
                if (this.deviceIsNull) {

                    return;
                }
                //
                if (this.codeSource === 1) {
                    this.getWxAndDeviceAndDiscount();
                    return;
                }
                //
                if (this.codeSource === 2) {
                    this.callbackReq = getCallBackReq1();
                    this.getAliAndDeviceAndDiscount();
                }
            }
            // 校验设备号（code页面已保存）
            ,
            checkDeviceSn() {
                this.deviceSn = sessionStorage.getItem('DEVICE_SN');
                if (isEmpty(this.deviceSn)) {
                    // 缓存没找到，从链接获取。
                    let ali = getCallBackReq1();
                    this.deviceSn = ali.deviceSn;
                    if (isEmpty(this.deviceSn)) {
                        //
                        this.deviceIsNull = true;
                        this.description = '设备号获取失败，请重试';
                    }
                }
            }
            // 获取缓存中的用户（用于页面刷新）
            ,
            getUserBySession() {
                this.user = {};
                var userJsonStr = sessionStorage.getItem('USER');
                if (isNotEmpty(userJsonStr)) {
                    this.user = JSON.parse(userJsonStr);
                    this.getDeviceAndPortAndDiscount();
                }
            }
            // 获取扫码来源
            ,
            getCodeSource() {
                if (this.$client.WEIXIN) {
                    this.codeSource = 1;
                } else if (this.$client.ALIPAY) {
                    this.codeSource = 2;
                }
            }
            // 判断用户code、扫码来源，是否都OK
            ,
            checkCallbackAndSource() {
                if (isEmpty(this.callbackReq) || isEmpty(this.codeSource)) {
                    this.deviceIsNull = true;
                    this.description = '用户Code获取失败';
                }
            }
            // 获取微信token、微信用户信息、设备信息、设备端口、设备套餐
            ,
            getWxAndDeviceAndDiscount() {
                let that = this;
                axios.get(getWxTokenUrl(this.callbackReq.code))
                    .then(resp => {
                        if (isEmpty(resp) || isEmpty(resp.data)) {
                            this.deviceIsNull = true;
                            this.description = '微信Token获取失败';
                            return;
                        }
                        //
                        axios.get(getWxUserInfoUrl(resp.data.access_token, resp.data.openid))
                            .then(resp1 => {
                                //
                                if (isEmpty(resp1) || isEmpty(resp1.data)) {
                                    this.deviceIsNull = true;
                                    this.description = '微信用户获取失败';
                                    return;
                                }
                                //
                                this.$api.api.req(this.$api.user.getWxUserLogin(JSON.stringify(resp1.data))).then(resp2 => {
                                    //
                                    if (isEmpty(resp2) || isEmpty(resp2.data)) {
                                        this.deviceIsNull = true;
                                        this.description = '微信登录失败';
                                        return;
                                    }
                                    //
                                    this.user = resp2.data[0];
                                    // alert('user:'+JSON.stringify(this.user))
                                    sessionStorage.setItem('USER', JSON.stringify(this.user));
                                    localStorage.setItem('USER', JSON.stringify(this.user));
                                    //
                                    this.getDeviceAndPortAndDiscount();
                                });
                            }).catch(function (error) {
                            this.deviceIsNull = true;
                            this.description = '微信用户获取异常';
                        });
                    }).catch(function (error) {
                    this.deviceIsNull = true;
                    this.description = '微信Token获取异常';
                });
            }
            // 查询支付宝、端口、套餐等
            ,
            getAliAndDeviceAndDiscount() {
                let ali = {};
                ali.code = this.callbackReq.auth_code;
                this.$api.api.req(this.$api.user.getAliUserLogin(ali)).then(resp => {
                    //
                    if (isEmpty(resp) || isEmpty(resp.data)) {
                        this.deviceIsNull = true;
                        this.description = '支付宝登录失败';
                        return;
                    }
                    //
                    this.user = resp.data[0];
                    sessionStorage.setItem('USER', JSON.stringify(this.user));
                    localStorage.setItem('USER', JSON.stringify(this.user));
                    //
                    this.getDeviceAndPortAndDiscount();
                }).catch(error => {
                    this.deviceIsNull = true;
                    this.description = '支付宝登录异常';
                });
            }
            //个人信息
            ,getInfo() {
                this.$router.push({name: 'info', params: {userId: this.user.ID}});
            }
            // 历史订单
            ,
            historyOrder() {
                this.$router.push({name: 'order', params: {userId: this.user.ID}});
            }
            // 设备端口查询
            ,
            port: function (item) {
                //
                this.curPort = null;
                if (item.Status === 0) {
                    this.$toast.fail("端口使用中");
                    return;
                }
                if (item.Status === 2) {
                    this.$toast.fail("端口故障");
                    return;
                }
                let index = item.PortSn;
                let portId = item.ID;
                this.curPort = item;
                // 选中
                if (null == this.portId) {
                    this.portActive(item.PortSn, portId);
                    return;
                }
                // 取消
                if (this.portId === portId) {
                    this.portNoActive(index);
                    return;
                }
                // 已有其他，关闭上一个选中的
                this.portNoActive(this.portIndex)
                this.curPort = item;
                this.portActive(index, portId)
            }

            // 处理套餐选中
            ,
            chooseDiscount: function (item) {
                this.curDiscount = item;
                // 选中
                if (null == this.discountIndex) {
                    this.discountActive(item.ID);
                    //
                    return;
                }
                // 取消
                if (this.discountIndex === item.ID) {
                    this.discountNoActive(item.ID);
                    return;
                }
                // 已有其他，关闭上一个选中的
                this.discountNoActive(this.discountIndex);
                this.curDiscount = item;
                this.discountActive(item.ID);
            }
            // 客服
            ,
            customerService() {
                window.location.href = 'tel:15340996979'
            }
            // 优惠套餐
            ,
            discount() {
                Dialog.alert({
                    title: '优惠套餐',
                    message: '弹窗内容',
                    theme: 'round-button',
                }).then(() => {
                    // on close
                });
            }
            // 支付
            ,
            pay() {
                //
                if (isEmpty(this.user)) {
                    this.$toast.fail("用户异常，请重新扫码");
                    return;
                }
                if (isEmpty(this.device)) {
                    this.$toast.fail("设备异常，请重新扫码");
                    return;
                }
                if (isEmpty(this.curPort)) {
                    this.$toast.fail("请选择空闲端口");
                    return;
                }
                if (isEmpty(this.curDiscount)) {
                    this.$toast.fail("请选择套餐");
                    return;
                }
                if (isEmpty(this.codeSource)) {
                    this.getCodeSource();
                }
                //
                let data = this.$api.pay.initPayParams(this.user, this.device, this.curPort, this.curDiscount);
                //
                if (this.codeSource === 1) {
                    data.PaymentType = 1;
                    data.Openid = this.user.WXOpenid;
                    data.TransactionPrice = data.TransactionPrice * 100;
                    this.$api.api.req(this.$api.pay.weiXin(data)).then(resp => {
                        //
                        if (isEmpty(resp.data)) {
                            this.$toast.fail('支付失败，请重试');
                            return;
                        }
                        //
                        sessionStorage.setItem('ORDER', JSON.stringify(resp.data.OrderTransactionsmodel));
                        //
                        this.$api.pay.wxPay(resp.data.wxJsApiParam, this);
                    }).catch(resp => {
                        this.$toast.fail('支付失败，请重试');
                    });
                } else if (this.codeSource === 2) {
                    data.PaymentType = 2;
                    data.Openid = this.user.ZFBOpenid;
                    this.$api.api.req(this.$api.pay.aLi(data)).then(resp => {
                        //
                        if (isEmpty(resp.data)) {
                            this.$toast.fail('支付失败，请重试');
                            return;
                        }
                        //
                        const div = document.createElement('div')
                        div.innerHTML = resp.data.zfbJsApiParam;
                        document.body.appendChild(div);
                        document.forms[0].submit();
                    }).catch(resp => {
                        this.$toast.fail('支付失败，请重试');
                    });
                } else {
                    this.$toast.fail('支付方式错误，请重试');
                }
            }
            // 端口选中
            ,
            portActive(index, portId) {
                //
                this.portId = portId;
                this.portIndex = index;
                //
                let that = this.$refs['ports' + this.portIndex];
                that[0].$el.firstChild.style.border = "1px solid #1989fa"
                that[0].$el.firstChild.firstChild.style.color = "#1989fa";
            }
            // 端口取消选中
            ,
            portNoActive(index) {
                //
                this.portIndex = null;
                this.portId = null;
                this.curPort = null;
                //
                let that = this.$refs['ports' + index];
                that[0].$el.firstChild.style.border = ""
                that[0].$el.firstChild.firstChild.style.color = "";
            }
            // 套餐选中
            ,
            discountActive(index) {
                this.discountIndex = index;
                // let that = this.$refs['discount' + this.discountIndex];
                // alert('size:'+that.size())
                // this.$refs['discount' + this.discountIndex].style.border = "1px solid #1989fa";
            }
            // 套餐取消选中
            ,
            discountNoActive(index) {
                // alert('取消选中');
                this.discountIndex = null;
                this.curDiscount = null;
                // this.$refs['discount' + index].style.border = "";
            }
            // 查询设备、设备端口、套餐
            ,
            getDeviceAndPortAndDiscount() {
                this.$api.api.req(this.$api.device.getSysStates({})).then(resp => {
                    if (isEmpty(resp.data) || resp.data.UnderMaintenance=='1') {
                        this.deviceIsNull = true;
                        return;
                    }
                    let device = {};
                    //
                    if (isEmpty(this.user.ID)) {
                        return;
                    }
                    //
                    device.userID = this.user.ID;
                    device.DeviceSN = this.deviceSn.split('#')[0];
                    this.$api.api.req(this.$api.device.getDeviceBySn(device)).then(resp => {
                        //
                        if (isEmpty(resp.data) || resp.data.length === 0 || isEmpty(resp.data[0].IPAddress)||isEmpty(resp.data[0].MerchantsID)||resp.data[0].MerchantsID==-1) {
                            this.deviceIsNull = true;
                            return;
                        }
                        //
                        this.deviceIsNull = false;
                        // 当前设备
                        this.device = resp.data[0];
                        //
                        this.deviceSn = this.device.DeviceSN;
                        this.deviceSnTitle = this.deviceSnTitle + '-' + handleZero(this.deviceSn);
                        //
                        this.getPort(device);
                        this.getDiscount();
                    })
                })
                //
            }
            // 查询端口
            ,
            getPort(device) {
                //
                this.$api.api.req(this.$api.device.getDevicePort(device)).then(deviceResp => {
                    // 当前设备下的端口集合
                    this.devicePorts = deviceResp.data;
                });
            }
            // 查询套餐
            ,
            getDiscount() {
                if (isEmpty(this.deviceSn)) {
                    return;
                }
                //
                let that = this;
                let discount = {};
                //
                discount.DeviceSN = that.deviceSn;
                discount.userID = that.user.ID;
                //
                this.$api.api.req(this.$api.discount.getDiscountCustomer(discount)).then(resp => {
                    // 当前设备下的优惠套餐
                    that.discounts = resp.data;
                    //
                    this.finished = true
                    //
                    this.isLoading = false;
                });
            }

            // , wxPay(data, that) {
            //     if (typeof WeixinJSBridge == "undefined") {
            //         if (document.addEventListener) {
            //             document.addEventListener('WeixinJSBridgeReady', this.wxJsApiCall(data, that), false);
            //         } else if (document.attachEvent) {
            //             document.attachEvent('WeixinJSBridgeReady', this.wxJsApiCall(data, that));
            //             document.attachEvent('onWeixinJSBridgeReady', this.wxJsApiCall(data, that));
            //         }
            //     } else {
            //         this.wxJsApiCall(data, that);
            //     }
            // }
            //
            // , wxJsApiCall(data, that) {
            //     //
            //     let tmp = JSON.parse(data);
            //     WeixinJSBridge.invoke(
            //         'getBrandWCPayRequest', {
            //             "appId": tmp.appId,     //公众号名称，由商户传入
            //             "timeStamp": tmp.timeStamp,         //时间戳，自1970年以来的秒数
            //             "nonceStr": tmp.nonceStr,  //随机串
            //             "package": tmp.package,
            //             "signType": tmp.signType,         //微信签名方式：
            //             "paySign": tmp.paySign //微信签名
            //         },
            //         function (res) {
            //             //
            //             if (res.err_msg === 'get_brand_wcpay_request:cancel') {
            //                 return;
            //             }
            //             //
            //             if (res.err_msg === 'get_brand_wcpay_request:ok') {
            //                 that.$router.replace({name: 'item'});
            //                 return;
            //             }
            //             //
            //             Toast.fail('下单失败');
            //         });
            // }
        }
    }
</script>

<style scoped>
    .port-active {
        color: red;
        font-weight: bold;
    }
    .discount-active {
        border: 1px solid #1989fa;
        padding: 5px;
    }
    .discount-normal {
        border: 0;
        padding: 5px;
    }
    .class_list{
        padding: 2px;
    }
</style>
